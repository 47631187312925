/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IdDto,
  LoginHistoryDto,
  PostDto,
  PostReplyDto,
  ReportDto,
  StoreDto,
  TokenDto,
  UserDto,
  ValidationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 내 정보 조회
   *
   * @tags user
   * @name GetMyInfo
   * @summary 내 정보 조회
   * @request GET:/v1/me
   * @secure
   */
  getMyInfo = (params: RequestParams = {}) =>
    this.request<UserDto, any>({
      path: `/v1/me`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 정보 변경
   *
   * @tags user
   * @name UpdateMyInfo
   * @summary 내 정보 변경
   * @request PUT:/v1/me
   * @secure
   */
  updateMyInfo = (data: UserDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/me`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 회원 탈퇴
   *
   * @tags user
   * @name Resign
   * @summary 회원 탈퇴
   * @request DELETE:/v1/me
   * @secure
   */
  resign = (query?: { deletePost?: boolean }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/me`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 로그인 토큰 발급(for test)
   *
   * @tags user
   * @name GetUserToken
   * @summary 로그인 토큰 발급(for test)
   * @request POST:/v1/users/{userId}/token
   * @secure
   */
  getUserToken = (userId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/users/${userId}/token`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 유저 신고
   *
   * @tags user
   * @name ReportUser
   * @summary 유저 신고
   * @request POST:/v1/users/{userId}/report
   * @secure
   */
  reportUser = (userId: number, data: ReportDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/users/${userId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 푸시 토큰 업데이트
   *
   * @tags user
   * @name UpdatePushToken
   * @summary 푸시 토큰 업데이트
   * @request POST:/v1/update-push-token
   * @secure
   */
  updatePushToken = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/update-push-token`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 로그인 토큰 재발급
   *
   * @tags user
   * @name RefreshToken
   * @summary 로그인 토큰 재발급
   * @request POST:/v1/refresh-token
   * @secure
   */
  refreshToken = (params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/refresh-token`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그아웃
   *
   * @tags user
   * @name Logout
   * @summary 로그아웃
   * @request POST:/v1/logout
   * @secure
   */
  logout = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/logout`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 로그인
   *
   * @tags user
   * @name Login
   * @summary 로그인
   * @request POST:/v1/login
   * @secure
   */
  login = (data: UserDto, query?: { test?: boolean }, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/login`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 유저 리스트 조회
   *
   * @tags user
   * @name GetUsers
   * @summary 유저 리스트 조회
   * @request GET:/v1/users
   * @secure
   */
  getUsers = (
    query?: {
      searchKeyword?: string;
      name?: string;
      phoneNumber?: string;
      email?: string;
      isVerify?: boolean;
      isActive?: boolean;
      isAdmin?: boolean;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<UserDto[], any>({
      path: `/v1/users`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 유저 조회
   *
   * @tags user
   * @name GetUser
   * @summary 유저 조회
   * @request GET:/v1/users/{userId}
   * @secure
   */
  getUser = (userId: number, params: RequestParams = {}) =>
    this.request<UserDto, any>({
      path: `/v1/users/${userId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 유저가 작성한 커뮤니티 게시글 리스트 조회
   *
   * @tags user
   * @name GetUserCommunityPosts
   * @summary 유저가 작성한 커뮤니티 게시글 리스트 조회
   * @request GET:/v1/users/{userId}/community-posts
   * @secure
   */
  getUserCommunityPosts = (
    userId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/users/${userId}/community-posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 유저가 작성한 커뮤니티 댓글 리스트 조회
   *
   * @tags user
   * @name GetUserCommunityPostReplies
   * @summary 유저가 작성한 커뮤니티 댓글 리스트 조회
   * @request GET:/v1/users/{userId}/community-post-replies
   * @secure
   */
  getUserCommunityPostReplies = (
    userId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostReplyDto[], any>({
      path: `/v1/users/${userId}/community-post-replies`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 닉네임 유효성 검사
   *
   * @tags user
   * @name UserNameValidationCheck
   * @summary 닉네임 유효성 검사
   * @request GET:/v1/user-name-validation-check
   * @secure
   */
  userNameValidationCheck = (query: { name: string }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/user-name-validation-check`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 휴대폰번호 유효성 검사
   *
   * @tags user
   * @name PhoneNumberValidationCheck
   * @summary 휴대폰번호 유효성 검사
   * @request GET:/v1/phone-number-validation-check
   * @secure
   */
  phoneNumberValidationCheck = (query: { phoneNumber: string }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/phone-number-validation-check`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 휴대폰번호 중복 체크
   *
   * @tags user
   * @name PhoneNumberDuplicateCheck
   * @summary 휴대폰번호 중복 체크
   * @request GET:/v1/phone-number-duplicate-check
   * @secure
   */
  phoneNumberDuplicateCheck = (query: { phoneNumber: string }, params: RequestParams = {}) =>
    this.request<ValidationDto, any>({
      path: `/v1/phone-number-duplicate-check`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 상점 리스트 조회
   *
   * @tags user
   * @name GetMyStores
   * @summary 내 상점 리스트 조회
   * @request GET:/v1/me/stores
   * @secure
   */
  getMyStores = (params: RequestParams = {}) =>
    this.request<StoreDto[], any>({
      path: `/v1/me/stores`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 로그인 히스토리 조회
   *
   * @tags user
   * @name GetMyLoginHistory
   * @summary 내 로그인 히스토리 조회
   * @request GET:/v1/me/login-history
   * @secure
   */
  getMyLoginHistory = (
    query?: {
      'user.name'?: string;
      'user.phoneNumber'?: string;
      'user.email'?: string;
      'user.isAdmin'?: boolean;
      loginDate?: string;
      deviceModel?: string;
      deviceOs?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<LoginHistoryDto[], any>({
      path: `/v1/me/login-history`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
