import React from 'react';

import styled from 'styled-components';
import { Button } from '@mui/material';
import { RefreshTokenForce } from 'libs/commons/RefreshToken';

const ErrorPage = () => {
  const handleReload = async () => {
    await RefreshTokenForce();
    window.location.href = 'content';
  };

  return (
    <MainLayoutStyle>
      <img src="./images/dummy.png" alt="" />
      <br />
      죄송합니다.
      <br /> 데이터 취득중 일시적인 오류가 발생하였습다.
      <br /> 계속 오류가 발생하는 경우, <br />
      앱을 재 실행해 주시기 바랍니다.
      <div>
        <Button onClick={handleReload}>페이지 새로고침</Button>
      </div>
      <hr />
      <div
        onClick={() => {
          window.location.href = 'erroradmin';
        }}
        style={{ color: '#f8f8f8' }}
      >
        test page
      </div>
    </MainLayoutStyle>
  );
};

const MainLayoutStyle = styled.div`
  text-align: center;
  margin-top: 200px;
`;

export default ErrorPage;
