import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { ItemDto, PostDto } from 'services/data-contracts';
import NumberFormat from 'react-number-format';
import { RowStyle } from 'components/styles/RowStyle';
import { Avatar } from '@mui/material';

const HumorList = observer(() => {
  const { communityPostStore } = useStore();

  return (
    <>
      <Swiper spaceBetween={10} slidesPerView={2.3}>
        {communityPostStore.posts?.map((post: PostDto) => (
          <SwiperSlide>
            <MainEventStyle
              onClick={() => {
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    deepLink: `sangjang://community/post?post_id=${post?.id}&isReply=false`,
                  }),
                );
              }}
            >
              <ImageContainerStyle>
                <img
                  style={{
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                  src={post.creator?.profileImage?.thumbnailUrl}
                  alt=""
                />
              </ImageContainerStyle>

              <div style={{ fontSize: 14, textAlign: 'left' }}>{post.title}</div>
              <RowStyle style={{ justifyContent: 'flex-start', marginTop: 10 }}>
                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }}>
                  <img
                    src={post.creator?.profileImage?.thumbnailUrl || './images/dummy.png'}
                    style={{ width: 24, height: 24 }}
                    alt=""
                  />
                </Avatar>
                <div style={{ fontSize: 12 }}>{post.creator?.name}</div>
              </RowStyle>
            </MainEventStyle>
          </SwiperSlide>
        ))}
        <SwiperSlide>more</SwiperSlide>
      </Swiper>
    </>
  );
});

const MainEventStyle = styled.div`
  width: 100%;
`;

const ImageContainerStyle = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 160px;
`;
export default HumorList;
