import { RoutePropInterface } from 'types/CommonTypes';
import * as Pages from 'pages';

const mainRoutes: RoutePropInterface[] = [
  {
    path: '/',
    title: 'livelink',
    element: Pages.HomePage,
  },
  {
    path: '/community',
    title: 'livelink',
    element: Pages.CommunityPage,
  },
  {
    path: '/content',
    title: 'livelink',
    element: Pages.CommunityPage,
  },
  {
    path: '/viewer/:communityid/:postid',
    title: 'livelink',
    element: Pages.ContentViewerPage,
  },
  {
    path: '/error',
    title: 'livelink',
    element: Pages.ErrorPage,
  },
  {
    path: '/erroradmin',
    title: 'livelink',
    element: Pages.ErrorPageAdmin,
  },
];

export default mainRoutes;
