import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

const ContentViewerPage = observer(() => {
  const { communityPostStore } = useStore();
  const { postid } = useParams();
  const init = async () => {
    if (typeof postid !== 'undefined') {
      communityPostStore.getItem(Number(postid));
    }
  };

  useEffect(() => {
    init();
  }, []);

  return <MainLayoutStyle>{communityPostStore.post?.title}</MainLayoutStyle>;
});

const MainLayoutStyle = styled.div``;

export default ContentViewerPage;
