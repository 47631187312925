/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IdDto,
  InquiryDto,
  ItemDto,
  ItemOfflineReservationDto,
  ItemSummaryDto,
  OfferDto,
  OrderDto,
  RegionCountDto,
  ReportDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Item<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 상품 상세 조회
   *
   * @tags item
   * @name GetItem
   * @summary 상품 상세 조회
   * @request GET:/v1/market/items/{itemId}
   * @secure
   */
  getItem = (itemId: number, params: RequestParams = {}) =>
    this.request<ItemDto, any>({
      path: `/v1/market/items/${itemId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 수정
   *
   * @tags item
   * @name UpdateItem
   * @summary 상품 수정
   * @request PUT:/v1/market/items/{itemId}
   * @secure
   */
  updateItem = (itemId: number, data: ItemDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 삭제
   *
   * @tags item
   * @name DeleteItem
   * @summary 상품 삭제
   * @request DELETE:/v1/market/items/{itemId}
   * @secure
   */
  deleteItem = (itemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 물물교환 조회시 itemType:"BARTER"<br>[인기순] sort: ["interestCount,desc","sortDate,desc"]<br>[등록순] sort: ["sortDate,desc"]<br>[관심] interestCount<br>[댓글] marketChatRoomCount
   *
   * @tags item
   * @name GetItems
   * @summary 상품 리스트 조회
   * @request GET:/v1/market/items
   * @secure
   */
  getItems = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      condition?: 'NEW' | 'USED';
      startDate?: string;
      endDate?: string;
      minPrice?: number;
      maxPrice?: number;
      transactionType?: ('DIRECT' | 'DELIVERY' | 'DIRECT_AND_DELIVERY')[];
      includeShippingFee?: boolean;
      useOffer?: boolean;
      useSafePayment?: boolean;
      regionCode?: string;
      status?: 'ON_SALE' | 'RESERVED' | 'SOLD';
      isRefundable?: boolean;
      latitude?: number;
      longitude?: number;
      radius?: number;
      itemType?: string;
      barterType?: 'GOODS' | 'FOOD';
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ItemDto[], any>({
      path: `/v1/market/items`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 물물교환 등록일 경우<br>itemType : "BARTER"<br>itemOtherInfo {<br>	barterType : GOODS, // 물물교환타입(GOODS:물품, FOOD:음식)<br>	phoneNumber: "01012345678", // 연락처<br>	weekdayContactStartTime: "10:00", // 평일 연락 가능 시작 시간<br>	weekdayContactEndTime: "17:00", // 평일 연락 가능 마감 시간<br>	weekendContactStartTime: "14:00", // 주말 연락 가능 시작 시간<br>	weekendContactEndTime: "16:00", // 주말 연락 가능 마감 시간<br>	excludeHoliday: "true", // 공휴일 제외(연락 가능 시간)<br>	postalCode: "04799", // 우편번호<br>	address: "서울 성동구 아차산로17길 49", // 주소<br>	detailAddress: "1406호", // 상세주소<br>	preferGoods: "의자||선반||주방도구" // 선호물품(최대 3개, 구분자'||')<br>}
   *
   * @tags item
   * @name AddItem
   * @summary 상품 등록
   * @request POST:/v1/market/items
   * @secure
   */
  addItem = (data: ItemDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 예약하기
   *
   * @tags item
   * @name ReserveItem
   * @summary 상품 예약하기
   * @request POST:/v1/market/items/{itemId}/reserve
   * @secure
   */
  reserveItem = (itemId: number, data: OrderDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}/reserve`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 신고
   *
   * @tags item
   * @name ReportItem
   * @summary 상품 신고
   * @request POST:/v1/market/items/{itemId}/report
   * @secure
   */
  reportItem = (itemId: number, data: ReportDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 구매하기
   *
   * @tags item
   * @name OrderItem
   * @summary 상품 구매하기
   * @request POST:/v1/market/items/{itemId}/order
   * @secure
   */
  orderItem = (itemId: number, data: OrderDto, params: RequestParams = {}) =>
    this.request<OrderDto, any>({
      path: `/v1/market/items/${itemId}/order`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 오프라인 예약 수량 조회
   *
   * @tags item
   * @name GetItemOfflineReservation
   * @summary 상품 오프라인 예약 수량 조회
   * @request GET:/v1/market/items/{itemId}/offline-reservation
   * @secure
   */
  getItemOfflineReservation = (itemId: number, params: RequestParams = {}) =>
    this.request<ItemOfflineReservationDto, any>({
      path: `/v1/market/items/${itemId}/offline-reservation`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 오프라인 예약하기
   *
   * @tags item
   * @name CreateItemOfflineReservation
   * @summary 상품 오프라인 예약하기
   * @request POST:/v1/market/items/{itemId}/offline-reservation
   * @secure
   */
  createItemOfflineReservation = (
    itemId: number,
    data: ItemOfflineReservationDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}/offline-reservation`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 상품 오프라인 거래 완료하기
   *
   * @tags item
   * @name CompleteItemOfflineReservation
   * @summary 상품 오프라인 거래 완료하기
   * @request POST:/v1/market/items/{itemId}/offline-reservation/complete
   * @secure
   */
  completeItemOfflineReservation = (
    itemId: number,
    data: ItemOfflineReservationDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}/offline-reservation/complete`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 상품 오프라인 예약 취소하기
   *
   * @tags item
   * @name CancelItemOfflineReservation
   * @summary 상품 오프라인 예약 취소하기
   * @request POST:/v1/market/items/{itemId}/offline-reservation/cancel
   * @secure
   */
  cancelItemOfflineReservation = (
    itemId: number,
    data: ItemOfflineReservationDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}/offline-reservation/cancel`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 상품 가격 제시하기
   *
   * @tags item
   * @name OfferItem
   * @summary 상품 가격 제시하기
   * @request POST:/v1/market/items/{itemId}/offer
   * @secure
   */
  offerItem = (itemId: number, data: OfferDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}/offer`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 가격 제시 취소하기
   *
   * @tags item
   * @name CancelItemOffer
   * @summary 상품 가격 제시 취소하기
   * @request DELETE:/v1/market/items/{itemId}/offer
   * @secure
   */
  cancelItemOffer = (itemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}/offer`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 관심 상품 등록
   *
   * @tags item
   * @name AddInterestedItem
   * @summary 관심 상품 등록
   * @request POST:/v1/market/items/{itemId}/interest
   * @secure
   */
  addInterestedItem = (itemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}/interest`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 관심 상품 해제
   *
   * @tags item
   * @name DeleteInterestedItem
   * @summary 관심 상품 해제
   * @request DELETE:/v1/market/items/{itemId}/interest
   * @secure
   */
  deleteInterestedItem = (itemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}/interest`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 상품 문의 리스트 조회
   *
   * @tags item
   * @name GetItemInquiries
   * @summary 상품 문의 리스트 조회
   * @request GET:/v1/market/items/{itemId}/inquiries
   * @secure
   */
  getItemInquiries = (
    itemId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<InquiryDto[], any>({
      path: `/v1/market/items/${itemId}/inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 문의하기
   *
   * @tags item
   * @name CreateItemInquiry
   * @summary 상품 문의하기
   * @request POST:/v1/market/items/{itemId}/inquiries
   * @secure
   */
  createItemInquiry = (itemId: number, data: InquiryDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}/inquiries`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 추천 상품 리스트 조회
   *
   * @tags item
   * @name GetSuggestedItems
   * @summary 추천 상품 리스트 조회
   * @request GET:/v1/market/suggested-items
   * @secure
   */
  getSuggestedItems = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ItemDto[], any>({
      path: `/v1/market/suggested-items`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description itemType:"BARTER" (물물교환)
   *
   * @tags item
   * @name GetSuggestedItems1
   * @summary 추천 상품 리스트 조회(상품 타입별)
   * @request GET:/v1/market/suggested-items/{itemType}
   * @secure
   */
  getSuggestedItems1 = (
    itemType: string,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ItemDto[], any>({
      path: `/v1/market/suggested-items/${itemType}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 물물교환 조회시 itemType:"BARTER"
   *
   * @tags item
   * @name GetItemsByMap
   * @summary 지역기반 상품 검색
   * @request GET:/v1/market/map/items
   * @secure
   */
  getItemsByMap = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      condition?: 'NEW' | 'USED';
      startDate?: string;
      endDate?: string;
      minPrice?: number;
      maxPrice?: number;
      transactionType?: ('DIRECT' | 'DELIVERY' | 'DIRECT_AND_DELIVERY')[];
      includeShippingFee?: boolean;
      useOffer?: boolean;
      useSafePayment?: boolean;
      regionCode?: string;
      status?: ('ON_SALE' | 'RESERVED' | 'SOLD')[];
      isRefundable?: boolean;
      latitude?: number;
      longitude?: number;
      radius?: number;
      itemType?: string;
      barterType?: 'GOODS' | 'FOOD';
    },
    params: RequestParams = {},
  ) =>
    this.request<RegionCountDto[], any>({
      path: `/v1/market/map/items`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 현황 조회
   *
   * @tags item
   * @name GetItemSummary
   * @summary 상품 현황 조회
   * @request GET:/v1/market/items/{itemId}/summary
   * @secure
   */
  getItemSummary = (itemId: number, params: RequestParams = {}) =>
    this.request<ItemSummaryDto, any>({
      path: `/v1/market/items/${itemId}/summary`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 예약 리스트 조회
   *
   * @tags item
   * @name GetItemReservations
   * @summary 상품 예약 리스트 조회
   * @request GET:/v1/market/items/{itemId}/reservations
   * @secure
   */
  getItemReservations = (
    itemId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<OrderDto[], any>({
      path: `/v1/market/items/${itemId}/reservations`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 가격 제시 리스트 조회
   *
   * @tags item
   * @name GetItemOffers
   * @summary 상품 가격 제시 리스트 조회
   * @request GET:/v1/market/items/{itemId}/offers
   * @secure
   */
  getItemOffers = (
    itemId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<OfferDto[], any>({
      path: `/v1/market/items/${itemId}/offers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 가격 제시받은 상품 리스트 조회
   *
   * @tags item
   * @name GetOfferItems
   * @summary 가격 제시받은 상품 리스트 조회
   * @request GET:/v1/market/items/offered
   * @secure
   */
  getOfferItems = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ItemDto[], any>({
      path: `/v1/market/items/offered`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내가 제시한 상품 가격 제시 리스트 조회
   *
   * @tags item
   * @name GetMyOffers
   * @summary 내가 제시한 상품 가격 제시 리스트 조회
   * @request GET:/v1/market/items/my-offers
   * @secure
   */
  getMyOffers = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<OfferDto[], any>({
      path: `/v1/market/items/my-offers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 물물교환 조회시 itemType:"BARTER"<br>[인기순] sort: ["interestCount,desc","createDate,desc"]<br>[등록순] sort: ["createDate,desc"]<br>[관심] interestCount<br>[댓글] marketChatRoomCount
   *
   * @tags item
   * @name GetInterestedItems
   * @summary 관심 상품 리스트 조회
   * @request GET:/v1/market/items/interested
   * @secure
   */
  getInterestedItems = (
    query?: {
      itemType?: string;
      barterType?: 'GOODS' | 'FOOD';
      regionCode?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ItemDto[], any>({
      path: `/v1/market/items/interested`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
