import React from 'react';
import 'swiper/css';
import styled from 'styled-components';

const Greetings = ({ name }: { name?: string }) => {
  return (
    <div style={{ textAlign: 'left' }}>
      <MainGreetingStyle>{name}님!</MainGreetingStyle>
      <SubGreetingStyle>오늘도 행복한 하루되세요:D</SubGreetingStyle>
    </div>
  );
};

const MainGreetingStyle = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
const SubGreetingStyle = styled.div``;

export default Greetings;
