import React, { useEffect } from 'react';
import { RowStyle } from 'components/styles/RowStyle';
import { useStore } from 'stores/StoreHelper';
import { Avatar } from '@mui/material';
import { observer } from 'mobx-react';
import SubEventComponent from 'features/main/SubEventComponent copy';
import MainEventComponent from 'features/main/MainEventComponent';
import CommunityList from 'features/main/CommunityList';
import HumorList from 'features/main/HumorList';
import Greetings from 'features/main/Greetings';
import ItemList from 'features/main/ItemList';
import styled from 'styled-components';

type screenDeepLinkTypes = 'market' | 'community' | 'property' | 'talk' | 'profile';

const MainPage = observer(() => {
  const { communityPostStore, marketStore } = useStore();

  const init = async () => {
    await communityPostStore.getPosts();
    await communityPostStore.getHotIssuePosts();
    await marketStore.getItems();
  };

  // 각 메인 스크린으로 이동시키는 핸들러
  const handleGoTargetScreen = async (target: screenDeepLinkTypes) => {
    switch (target) {
      case 'market':
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ deepLink: 'sangjang://market/main' }),
        );
      case 'community':
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ deepLink: `sangjang://community/main` }),
        );
      case 'property':
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ deepLink: `sangjang://property/main` }),
        );
      case 'talk':
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ deepLink: `sangjang://talk/main` }),
        );
      case 'profile':
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ deepLink: `sangjang://profile/main` }),
        );
      default:
        break;
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <MainLayoutStyle>
      <RowStyle>
        <Greetings />
        <Avatar onClick={() => handleGoTargetScreen('profile')}>d</Avatar>
      </RowStyle>
      <SectionStyle>
        <MainEventComponent />
      </SectionStyle>
      <SectionStyle>
        <RowStyle>
          <HeadlineStyle>상인장터 특집!!</HeadlineStyle>
          <div>더보기</div>
        </RowStyle>
        <SubEventComponent />
      </SectionStyle>
      <Section2Style>
        <img src="./images/main_banner.png" alt="" />
      </Section2Style>
      <SectionStyle>
        <RowStyle>
          <HeadlineStyle>번영회최신글</HeadlineStyle>
          <div onClick={() => handleGoTargetScreen('community')}>더보기</div>
        </RowStyle>
        <CommunityList posts={communityPostStore.hotPosts} />
      </SectionStyle>
      <SectionStyle>
        <RowStyle>
          <HeadlineStyle>웃고가세요</HeadlineStyle>
          <div>더보기</div>
        </RowStyle>
        <HumorList />
      </SectionStyle>
      <SectionStyle>
        <RowStyle>
          <HeadlineStyle>장터추천상품</HeadlineStyle>
          <div onClick={() => handleGoTargetScreen('market')}>더보기</div>
        </RowStyle>
        {/* <ItemList /> */}
      </SectionStyle>
      <Section2Style>
        <img src="./images/sub_banner.png" alt="" />
      </Section2Style>
    </MainLayoutStyle>
  );
});

const MainLayoutStyle = styled.div``;
const Section2Style = styled.div`
  border-radius: 15px;
  width: 100%;
  & img {
    width: 100%;
  }
`;
const SectionStyle = styled.div`
  margin: 20px 0;
  background: #fff;
  padding: 15px;
  border-radius: 15px;

  /* box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4); */
`;

const HeadlineStyle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
`;
export default MainPage;
