import React from 'react';
import styled from 'styled-components';
import { PostDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/ko';

const CommunityList = observer(
  ({ posts, useThumbnail }: { posts: Array<PostDto>; useThumbnail?: boolean }) => {
    const getImage = (post: PostDto) => {
      const content = post.contents;
      if (typeof post.files !== 'undefined' && post.files?.length > 0) {
        return <ImageStyle src={`${post.files[0].thumbnailUrl}`} alt="" />;
      } else if (typeof content !== 'undefined') {
        const re = /<img[^>]+src="https:\/\/([^">]+)/g;
        const results = re.exec(content);
        if (results !== null) {
          return <ImageStyle src={`https://${results[1]}`} alt="" />;
        }
      }
      return <></>;
    };

    return (
      <>
        {posts?.map((post: PostDto) => (
          <PostStyle
            key={post.id}
            onClick={() => {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  deepLink: `sangjang://community/post?post_id=${post?.id}&isReply=false`,
                }),
              );
            }}
          >
            <TitleStyle useThumbnail={useThumbnail}>
              <div style={{ lineHeight: 1.3, fontSize: 14 }}>{post.title}</div>
              <AvatarStyle>
                <div style={{ fontWeight: 400, fontSize: 12 }}>{post.creator?.name}</div>
                <div style={{ fontSize: 12, marginLeft: 10 }}>
                  {moment(post.createDate).fromNow()}
                </div>
              </AvatarStyle>
            </TitleStyle>
            {useThumbnail !== false && <ImageContainerStyle>{getImage(post)}</ImageContainerStyle>}
          </PostStyle>
        ))}
      </>
    );
  },
);

const PostStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TitleStyle = styled.div<{ useThumbnail?: boolean }>`
  text-align: left;
  width: ${(props) => (props.useThumbnail ? 'calc(100% - 70px)' : '100%')};
  margin-right: 10px;
  position: relative;
  font-weight: 500;
`;

const ImageContainerStyle = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 8px;
  margin: 2px;
`;

const ImageStyle = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const AvatarStyle = styled.div`
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  margin-top: 5px;
`;

export default CommunityList;
