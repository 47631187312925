import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SubEventDef } from 'features/definitions/definition';
import 'swiper/css';
import styled from 'styled-components';

const SubEventComponent = () => {
  return (
    <>
      <Swiper spaceBetween={10} slidesPerView={2.15}>
        {SubEventDef.map((event: any) => (
          <SwiperSlide>
            <ImageContainerStyle>
              <img
                style={{
                  objectPosition: 'center center',
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
                src={event.imageSrc}
                alt=""
              />
            </ImageContainerStyle>

            <EventTypeStyle>{event.type}</EventTypeStyle>
            <EventTitleStyle>{event.title}</EventTitleStyle>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

const MainEventStyle = styled.div``;
const ImageContainerStyle = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 120px;
`;

const EventTypeStyle = styled.div`
  font-size: 12px;
  color: #0346ff;
  text-align: left;
  margin: 5px;
  font-weight: 500;
`;

const EventTitleStyle = styled.div`
  font-size: 13px;
  text-align: left;
  margin: 5px;
  font-weight: 500;
`;

export default SubEventComponent;
