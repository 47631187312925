/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommunityBoardDto,
  CommunityCategoryDto,
  CommunityDto,
  CommunityInvitationDto,
  CommunityMainDto,
  CommunityMainSearchDto,
  CommunityMemberDto,
  CommunityMembershipDto,
  IdDto,
  LeaveCommunityDto,
  PostDto,
  PostFileDto,
  PostLinkDto,
  ReportDto,
  ValidationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Community<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 커뮤니티 리스트 조회
   *
   * @tags community
   * @name GetCommunities
   * @summary 커뮤니티 리스트 조회
   * @request GET:/v1/communities
   * @secure
   */
  getCommunities = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      regionCode?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<CommunityDto[], any>({
      path: `/v1/communities`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 생성
   *
   * @tags community
   * @name CreateCommunity
   * @summary 커뮤니티 생성
   * @request POST:/v1/communities
   * @secure
   */
  createCommunity = (data: CommunityDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/communities`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 신고
   *
   * @tags community
   * @name ReportCommunity
   * @summary 커뮤니티 신고
   * @request POST:/v1/communities/{communityId}/report
   * @secure
   */
  reportCommunity = (communityId: number, data: ReportDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 탈퇴
   *
   * @tags community
   * @name LeaveCommunity
   * @summary 커뮤니티 탈퇴
   * @request POST:/v1/communities/{communityId}/leave
   * @secure
   */
  leaveCommunity = (communityId: number, data: LeaveCommunityDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/leave`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 커뮤니티 가입(가입 신청)
   *
   * @tags community
   * @name JoinCommunity
   * @summary 커뮤니티 가입(가입 신청)
   * @request POST:/v1/communities/{communityId}/join
   * @secure
   */
  joinCommunity = (communityId: number, query?: { key?: string }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/join`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 초대장 생성(또는 기존 초대장 유효기간 초기화하여 가져오기)
   *
   * @tags community
   * @name CreateInvitation
   * @summary 커뮤니티 초대장 생성
   * @request POST:/v1/communities/{communityId}/invitations
   * @secure
   */
  createInvitation = (
    communityId: number,
    data: CommunityInvitationDto,
    params: RequestParams = {},
  ) =>
    this.request<CommunityInvitationDto, any>({
      path: `/v1/communities/${communityId}/invitations`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 가입 신청 취소
   *
   * @tags community
   * @name DeleteCommunityJoinRequest
   * @summary 커뮤니티 가입 신청 취소
   * @request POST:/v1/communities/{communityId}/cancel-join
   * @secure
   */
  deleteCommunityJoinRequest = (communityId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/cancel-join`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 게시판 리스트 조회
   *
   * @tags community
   * @name GetCommunityBoards
   * @summary 커뮤니티 게시판 리스트 조회
   * @request GET:/v1/communities/{communityId}/boards
   * @secure
   */
  getCommunityBoards = (communityId: number, params: RequestParams = {}) =>
    this.request<CommunityBoardDto[], any>({
      path: `/v1/communities/${communityId}/boards`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 메인 정보 조회
   *
   * @tags community
   * @name GetCommunityMain
   * @summary 커뮤니티 메인 정보 조회
   * @request GET:/v1/community-main
   * @secure
   */
  getCommunityMain = (params: RequestParams = {}) =>
    this.request<CommunityMainDto, any>({
      path: `/v1/community-main`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 메인 검색
   *
   * @tags community
   * @name GetCommunityMainSearch
   * @summary 커뮤니티 메인 검색
   * @request GET:/v1/community-main/search
   * @secure
   */
  getCommunityMainSearch = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      regionCode?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<CommunityMainSearchDto, any>({
      path: `/v1/community-main/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 초대장 키로 커뮤니티 초대장 조회
   *
   * @tags community
   * @name GetInvitation
   * @summary 초대장 키로 커뮤니티 초대장 조회
   * @request GET:/v1/community-invitation
   * @secure
   */
  getInvitation = (query: { key: string }, params: RequestParams = {}) =>
    this.request<CommunityInvitationDto, any>({
      path: `/v1/community-invitation`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 주제 리스트 조회
   *
   * @tags community
   * @name GetCommunityCategories
   * @summary 커뮤니티 주제 리스트 조회
   * @request GET:/v1/community-categories
   * @secure
   */
  getCommunityCategories = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<CommunityCategoryDto[], any>({
      path: `/v1/community-categories`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 조회
   *
   * @tags community
   * @name GetCommunity
   * @summary 커뮤니티 조회
   * @request GET:/v1/communities/{communityId}
   * @secure
   */
  getCommunity = (communityId: number, query?: { key?: string }, params: RequestParams = {}) =>
    this.request<CommunityDto, any>({
      path: `/v1/communities/${communityId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 동영상 리스트 조회
   *
   * @tags community
   * @name GetCommunityVideos
   * @summary 커뮤니티 동영상 리스트 조회
   * @request GET:/v1/communities/{communityId}/videos
   * @secure
   */
  getCommunityVideos = (
    communityId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostFileDto[], any>({
      path: `/v1/communities/${communityId}/videos`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 게시글 리스트 조회
   *
   * @tags community
   * @name GetCommunityPosts
   * @summary 커뮤니티 게시글 리스트 조회
   * @request GET:/v1/communities/{communityId}/posts
   * @secure
   */
  getCommunityPosts = (
    communityId: number,
    query?: {
      'board.id'?: number;
      searchKeyword?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/communities/${communityId}/posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 공지사항 리스트 조회
   *
   * @tags community
   * @name GetCommunityNotices
   * @summary 커뮤니티 공지사항 리스트 조회
   * @request GET:/v1/communities/{communityId}/notices
   * @secure
   */
  getCommunityNotices = (
    communityId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/communities/${communityId}/notices`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티의 내 멤버쉽 조회
   *
   * @tags community
   * @name GetMyCommunityMembership
   * @summary 커뮤니티의 내 멤버쉽 조회
   * @request GET:/v1/communities/{communityId}/my-membership
   * @secure
   */
  getMyCommunityMembership = (communityId: number, params: RequestParams = {}) =>
    this.request<CommunityMembershipDto, any>({
      path: `/v1/communities/${communityId}/my-membership`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 멤버 리스트 조회
   *
   * @tags community
   * @name GetCommunityMembers
   * @summary 커뮤니티 멤버 리스트 조회
   * @request GET:/v1/communities/{communityId}/members
   * @secure
   */
  getCommunityMembers = (
    communityId: number,
    query?: {
      'user.name'?: string;
      isAdmin?: boolean;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<CommunityMemberDto[], any>({
      path: `/v1/communities/${communityId}/members`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 링크 리스트 조회
   *
   * @tags community
   * @name GetCommunityLinks
   * @summary 커뮤니티 링크 리스트 조회
   * @request GET:/v1/communities/{communityId}/links
   * @secure
   */
  getCommunityLinks = (
    communityId: number,
    query?: { searchKeyword?: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostLinkDto[], any>({
      path: `/v1/communities/${communityId}/links`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 사진 리스트 조회
   *
   * @tags community
   * @name GetCommunityImages
   * @summary 커뮤니티 사진 리스트 조회
   * @request GET:/v1/communities/{communityId}/images
   * @secure
   */
  getCommunityImages = (
    communityId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostFileDto[], any>({
      path: `/v1/communities/${communityId}/images`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 파일 리스트 조회
   *
   * @tags community
   * @name GetCommunityFiles
   * @summary 커뮤니티 파일 리스트 조회
   * @request GET:/v1/communities/{communityId}/files
   * @secure
   */
  getCommunityFiles = (
    communityId: number,
    query?: { searchKeyword?: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostFileDto[], any>({
      path: `/v1/communities/${communityId}/files`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 가입한 커뮤니티 리스트 조회
   *
   * @tags community
   * @name GetMyCommunities
   * @summary 가입한 커뮤니티 리스트 조회
   * @request GET:/v1/communities/mine
   * @secure
   */
  getMyCommunities = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<CommunityDto[], any>({
      path: `/v1/communities/mine`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 이름 중복체크
   *
   * @tags community
   * @name CheckDuplicateCommunityName
   * @summary 커뮤니티 이름 중복체크
   * @request GET:/v1/check-duplicate-community-name
   * @secure
   */
  checkDuplicateCommunityName = (query: { name: string }, params: RequestParams = {}) =>
    this.request<ValidationDto, any>({
      path: `/v1/check-duplicate-community-name`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
