const MainEventDef = [
  { linkTo: '', imageSrc: './images/mainevent1.jpg' },
  { linkTo: '', imageSrc: './images/mainevent2.jpg' },
];
const SubEventDef = [
  { linkTo: '', imageSrc: './images/mainevent1.jpg', type: 'Event', title: '특집 이벤트 제 1탄!' },
  {
    linkTo: '',
    imageSrc: './images/mainevent2.jpg',
    type: 'Interview',
    title: '특집 이벤트 제 1탄!',
  },
  {
    linkTo: '',
    imageSrc: './images/mainevent1.jpg',
    type: 'Interview',
    title: '특집 이벤트 제 1탄!',
  },
  {
    linkTo: '',
    imageSrc: './images/mainevent2.jpg',
    type: 'Interview',
    title: '특집 이벤트 제 1탄!',
  },
];

export { MainEventDef, SubEventDef };
