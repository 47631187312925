type screenDeepLinkTypes = 'market' | 'community' | 'property' | 'talk' | 'profile';

// 각 메인 스크린으로 이동시키는 핸들러
export const navigateTargetScreen = async (target: screenDeepLinkTypes) => {
  switch (target) {
    case 'market':
      return window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ deepLink: `sangjang://market/main` }),
      );
    case 'community':
      return window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ deepLink: `sangjang://community/main` }),
      );
    case 'property':
      return window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ deepLink: `sangjang://property/main` }),
      );
    case 'talk':
      return window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ deepLink: `sangjang://talk/main` }),
      );
    case 'profile':
      return window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ deepLink: `sangjang://profile/main` }),
      );
    default:
      break;
  }
};
