import AuthStore from 'stores/AuthStore';
import FileStore from 'stores/FileStore';
import UserStore from 'stores/UserStore';
import FormValidationStore from './FormValidationStore';
import UIStore from './UIStore';
import CommunityStore from './CommunityStore';
import CommunityPostStore from './CommunityPostStore';
import MarketStore from './MarketStore';
import PropertyStore from './PropertyStore';

const RootStore = {
  authStore: new AuthStore(),
  fileStore: new FileStore(),
  userStore: new UserStore(),
  formValidationStore: new FormValidationStore(),
  uiStore: new UIStore(),
  communityStore: new CommunityStore(),
  communityPostStore: new CommunityPostStore(),
  marketStore: new MarketStore(),
  propertyStore: new PropertyStore(),
};

export default RootStore;
