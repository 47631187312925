import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { ItemDto, PostDto } from 'services/data-contracts';
import { RowStyle } from 'components/styles/RowStyle';
import { Avatar } from '@mui/material';
import { navigateTargetScreen } from 'libs/helper/deeplinkHelper';

const ThumbnailList = observer(({ items }: { items: Array<PostDto> }) => {
  const getThumbnail = (post: PostDto) => {
    if (typeof post.files !== 'undefined' && post.files?.length > 0) {
      return post.files[0].thumbnailUrl;
    } else {
      const content = post.contents;
      if (typeof post.files !== 'undefined' && post.files?.length > 0) {
        return post.files[0].thumbnailUrl;
      } else if (typeof content !== 'undefined') {
        const re = /<img[^>]+src="https:\/\/([^">]+)/g;
        const results = re.exec(content);
        if (results !== null) {
          return `https://${results[1]}`;
        }
      }
    }
    return '';
  };
  return (
    <>
      <Swiper spaceBetween={10} slidesPerView={2.2}>
        {items?.map((item: PostDto) => (
          <SwiperSlide>
            <MainEventStyle
              onClick={() => {
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    deepLink: `sangjang://community/post?post_id=${item?.id}&isReply=false`,
                  }),
                );
              }}
            >
              <ImageContainerStyle>
                <img
                  style={{
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                  src={getThumbnail(item)}
                  alt=""
                />
              </ImageContainerStyle>

              <RowStyle>
                <div style={{ fontSize: 14, textAlign: 'left', marginTop: 10 }}>
                  {item?.title?.substring(0, 20)}
                </div>
              </RowStyle>
              <RowStyle style={{ justifyContent: 'flex-start', marginTop: 10 }}>
                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 5 }}>
                  <img
                    src={item.creator?.profileImage?.thumbnailUrl || './images/dummy.png'}
                    style={{ width: 24, height: 24 }}
                    alt=""
                  />
                </Avatar>
                <div style={{ fontSize: 12, opacity: 0.5 }}>
                  {item.creator?.name?.substring(0, 8)}
                </div>
              </RowStyle>
            </MainEventStyle>
          </SwiperSlide>
        ))}
        {/* <SwiperSlide>
          <MainEventStyle style={{}}>
            <MoreButtonStyle
              onClick={() => {
                navigateTargetScreen('market');
              }}
            >
              <img width="20" height="20" src="/images/dummy.png" alt="" />
              <br />
            </MoreButtonStyle>
            <div style={{ marginTop: 10 }}>더보기</div>
          </MainEventStyle>
        </SwiperSlide>*/}
      </Swiper>
    </>
  );
});

const MainEventStyle = styled.div`
  width: 100%;
`;

const ImageContainerStyle = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 140px;
`;

const MoreButtonStyle = styled.div`
  display: flex;
  margin: auto;
  width: 80px;
  margin-top: 20px;
  height: 80px;
  background: #efefef;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default ThumbnailList;
