import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MainEventDef } from 'features/definitions/definition';
import 'swiper/css';
import styled from 'styled-components';

const MainEventComponent = () => {
  return (
    <>
      <Swiper spaceBetween={20} slidesPerView={1.1}>
        {MainEventDef.map((event: any) => (
          <SwiperSlide>
            <MainEventStyle>
              <ContainerStyle>
                <img
                  style={{
                    objectPosition: 'center center',
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                  src={event.imageSrc}
                  alt=""
                />
              </ContainerStyle>
            </MainEventStyle>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

const ContainerStyle = styled.div`
  overflow: hidden;
  border-radius: 8px;
  height: 100%;
`;
const MainEventStyle = styled.div`
  height: calc(100vh - 400px);
`;

export default MainEventComponent;
