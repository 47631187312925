import React from 'react';
import { observer } from 'mobx-react';
import { Avatar } from '@mui/material';
import { RowStyle } from 'components/styles/RowStyle';
import { PropertyDto } from 'services/data-contracts';
import styled from 'styled-components';
import 'swiper/css';

const PropertyComponent = observer(({ item }: { item: PropertyDto }) => {
  return (
    <>
      <MainEventStyle
        onClick={() => {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ deepLink: `sangjang://property/post?property_id=${item.id}` }),
          );
        }}
      >
        <ImageContainerStyle>
          <img
            style={{
              objectPosition: 'center center',
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            src={item.image?.thumbnailUrl}
            alt=""
          />
        </ImageContainerStyle>
        <RowStyle style={{ fontSize: 12, justifyContent: 'flex-start' }}>
          {item.contractType === 'TRADING' ? (
            <PriceStyle style={{ background: ' #7963D2' }}>
              <div style={{ textAlign: 'left' }}>권 {item.price?.salePrice}</div>
            </PriceStyle>
          ) : (
            <>
              <PriceStyle style={{ background: ' #449CCE' }}>
                <div style={{ textAlign: 'left' }}>월 {item.price?.monthlyFee}</div>
              </PriceStyle>
              <PriceStyle style={{ background: ' #7AA94C', marginLeft: 3, marginRight: 3 }}>
                <div style={{ textAlign: 'left' }}>보 {item.price?.deposit}</div>
              </PriceStyle>
              <PriceStyle style={{ background: ' #F06D43' }}>
                <div style={{ textAlign: 'left' }}>권 {item.price?.premiumFee}</div>
              </PriceStyle>
            </>
          )}
        </RowStyle>
        <RowStyle>
          <div style={{ fontSize: 12, textAlign: 'left' }}>{item.name?.substring(0, 20)}</div>
        </RowStyle>
        <RowStyle style={{ justifyContent: 'flex-start', marginTop: 10 }}>
          <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 5 }}>
            <img
              src={item.store?.logoImage?.thumbnailUrl || './images/dummy.png'}
              style={{ width: 24, height: 24 }}
              alt=""
            />
          </Avatar>
          <div style={{ fontSize: 12, opacity: 0.5 }}>{item.store?.name?.substring(0, 8)}</div>
        </RowStyle>
      </MainEventStyle>
    </>
  );
});

const MainEventStyle = styled.div`
  width: 100%;
`;

const ImageContainerStyle = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 140px;
`;

const PriceStyle = styled.div`
  border-radius: 5px;
  padding: 0 7px;
  color: #fff;
  font-size: 12px;
  margin: 10px 0;
`;

export default PropertyComponent;
