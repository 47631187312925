import React from 'react';
import { observer } from 'mobx-react';
import { Avatar } from '@mui/material';
import { RowStyle } from 'components/styles/RowStyle';
import { ItemDto } from 'services/data-contracts';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import 'swiper/css';

const ItemComponent = observer(({ item }: { item: ItemDto }) => {
  return (
    <>
      <MainEventStyle
        onClick={() => {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ deepLink: `sangjang://market/product?itemId=${item.id}` }),
          );
        }}
      >
        <ImageContainerStyle>
          <img
            style={{
              objectPosition: 'center center',
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            src={item.image?.thumbnailUrl}
            alt=""
          />
        </ImageContainerStyle>
        <div style={{ textAlign: 'left', marginTop: 10 }}>
          {item.price === 0 ? (
            <div style={{ fontWeight: 600 }}>무료나눔</div>
          ) : (
            <NumberFormat
              displayType={'text'}
              value={item.price}
              thousandSeparator
              style={{ fontWeight: '600' }}
              suffix="원"
            />
          )}
        </div>
        <RowStyle>
          <div style={{ fontSize: 12, textAlign: 'left' }}>{item.name?.substring(0, 20)}</div>
        </RowStyle>
        <RowStyle style={{ justifyContent: 'flex-start', marginTop: 10 }}>
          <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 5 }}>
            <img
              src={item.store?.logoImage?.thumbnailUrl || './images/dummy.png'}
              style={{ width: 24, height: 24 }}
              alt=""
            />
          </Avatar>
          <div style={{ fontSize: 12, opacity: 0.5 }}>{item.store?.name?.substring(0, 8)}</div>
        </RowStyle>
      </MainEventStyle>
    </>
  );
});

const MainEventStyle = styled.div`
  width: 100%;
`;

const ImageContainerStyle = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 140px;
`;

export default ItemComponent;
