import { makeObservable, observable, flow } from 'mobx';
import { ItemDto } from 'services/data-contracts';
import { Property as PropertyService } from 'services/Property';
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';

class PropertyStore extends ServiceStoreBase {
  constructor() {
    super();

    makeObservable(this, {
      properties: observable,
      getProperties: flow,
    });
  }

  api: PropertyService<unknown> = new PropertyService({
    baseURL: REST_API_URL,
  });

  properties: Array<ItemDto>;

  *getProperties() {
    const { response } = yield this.api.getProperties({ status: 'ON_SALE', page: 0, size: 10 });
    this.properties = response.data;
  }
}

export default PropertyStore;
