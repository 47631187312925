import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PostDto } from 'services/data-contracts';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import CommunityList from './CommunityList';

import 'swiper/css';

type ItemType = {
  items: Array<PostDto>;
  options?: { useThumbnail: boolean };
};

const NewsSwiper = observer(
  ({
    items,
    swiperIndex,
    onSwipeChange,
  }: {
    items?: Array<ItemType>;
    swiperIndex?: number;
    onSwipeChange?: (e: any) => void;
  }) => {
    const [swiper, setSwiper] = useState<any>(null);

    useEffect(() => {
      if (typeof swiperIndex !== 'undefined') {
        if (swiper) swiper.slideTo(swiperIndex);
      }
    }, [swiperIndex]);
    return (
      <>
        {typeof items !== 'undefined' && (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={(e: any) => {
              if (typeof onSwipeChange === 'function') {
                onSwipeChange(e);
              }
            }}
            onSwiper={setSwiper}
          >
            {items.map((item: ItemType) => (
              <SwiperSlide>
                <MainEventStyle>
                  <CommunityList posts={item.items} useThumbnail={item.options?.useThumbnail} />
                </MainEventStyle>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </>
    );
  },
);

const MainEventStyle = styled.div`
  width: 100%;
`;

const ImageContainerStyle = styled.div`
  border-radius: 7px;
  overflow: hidden;
  height: 160px;
`;
export default NewsSwiper;
