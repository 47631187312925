import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { getTokens } from 'libs/helper/localStorageHelper';
import mainRoutes from 'routes/mainRoutes';
import { observer } from 'mobx-react';

import styled from 'styled-components';

const LayoutWrapper = observer(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ fromWebView: pathname === '/content' }),
    );
  }, [pathname]);

  return (
    <>
      <RouteContainerStyle>
        <Routes>
          {mainRoutes.map((route) => {
            return (
              <Route
                key={`route_${route.title}`}
                path={route.path}
                element={
                  <Auth isPrivate={route.authenticated}>
                    <route.element />
                  </Auth>
                }
              />
            );
          })}
        </Routes>
      </RouteContainerStyle>
    </>
  );
});

const Auth = ({ isPrivate, children }: { isPrivate?: boolean; children: JSX.Element }) => {
  const token = getTokens().accessToken !== null;

  if (isPrivate !== false) {
    if (token) {
      return children;
    } else {
      return children;
    }
  } else {
    return children;
  }
};

const RouteContainerStyle = styled.div`
  max-width: 1200px;
  padding: 15px;
  margin: auto;
  text-align: center;
  background: #f8f8f8;
`;

export default LayoutWrapper;
