import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { observer } from 'mobx-react';
import { navigateTargetScreen } from 'libs/helper/deeplinkHelper';
import ItemComponent from './ItemComponent';
import PropertyComponent from './PropertyComponent';
import styled from 'styled-components';
import 'swiper/css';

const ItemList = observer(({ items, type }: { items: any; type: 'market' | 'property' }) => {
  // const { marketStore } = useStore();

  return (
    <>
      <Swiper spaceBetween={10} slidesPerView={2.2}>
        {items?.map((item: any) => (
          <SwiperSlide>
            {type === 'market' ? <ItemComponent item={item} /> : <PropertyComponent item={item} />}
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <MainEventStyle style={{}}>
            <MoreButtonStyle
              onClick={() => {
                navigateTargetScreen(type);
              }}
            >
              <img width="20" height="20" src="/images/dummy.png" alt="" />
              <br />
            </MoreButtonStyle>
            <div style={{ marginTop: 10 }}>더보기</div>
          </MainEventStyle>
        </SwiperSlide>
      </Swiper>
    </>
  );
});

const MainEventStyle = styled.div`
  width: 100%;
`;

const MoreButtonStyle = styled.div`
  display: flex;
  margin: auto;
  width: 80px;
  margin-top: 20px;
  height: 80px;
  background: #efefef;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default ItemList;
