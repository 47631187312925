import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Button, setRef } from '@mui/material';
import { RefreshTokenForce } from 'libs/commons/RefreshToken';

const ErrorPageAdmin = () => {
  const [refreshed, setRefreshed] = useState<string | null>('');
  const [newToken, setnewToken] = useState<string | null>('');
  const [error, setError] = useState<string | null>('');
  const handleReload = async () => {
    await RefreshTokenForce();
    window.location.href = 'content';
  };

  useEffect(() => {
    // const token = localStorage.getItem('accessToken');
    // const token1 = localStorage.getItem('refreshToken');
    const refreshedTime = localStorage.getItem('refreshed');
    const error1 = localStorage.getItem('httpError');
    const newToken1 = localStorage.getItem('tokenOrigin');
    setRefreshed(refreshedTime);
    setnewToken(newToken1);
    setError(error1);
  }, []);
  return (
    <MainLayoutStyle>
      <img src="./images/dummy.png" alt="" />

      <div>
        <Button onClick={handleReload}>페이지 새로고침</Button>
      </div>
      <hr />
      <div style={{ margin: 10 }}>
        {/* <div>error:{error}</div> */}
        {/* <div>AccessTone : {accessToken}</div>
        <div>issued: {refreshToken}</div> */}
        {/* <div>info: {deviceInfo}</div> */}
        <div>refreshedTime: {refreshed}</div>
        <div>originToken: {newToken}</div>
        <div>error: {error}</div>
      </div>
    </MainLayoutStyle>
  );
};

const MainLayoutStyle = styled.div`
  text-align: center;

  margin-top: 200px;
`;

export default ErrorPageAdmin;
