import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { RowStyle } from 'components/styles/RowStyle';
import { PostDto } from 'services/data-contracts';
import { Avatar } from '@mui/material';
import { navigateTargetScreen } from 'libs/helper/deeplinkHelper';

import CircularProgress from '@mui/material/CircularProgress';
import Greetings from 'features/main/Greetings';
import styled from 'styled-components';
import ItemList from 'features/main/ItemList';
import NewsSwiper from 'features/main/NewsSwiper';
import ThumbnailList from 'features/main/ThumbnailList';

const CommunityPage = observer(() => {
  const { communityPostStore, communityStore, marketStore, userStore, authStore, propertyStore } =
    useStore();
  const [loaded, setLoaded] = useState<boolean>(false);

  const [news, setNews] = useState<Array<PostDto>>([]);
  const [dailyReports, setDailyReports] = useState<Array<PostDto>>([]);
  const [humors, setHumors] = useState<Array<PostDto>>([]);
  const [newPosts, setNewPosts] = useState<Array<PostDto>>([]);

  const [selectedCommunityIndex, setSelectedCommunityIndex] = useState<number>(0);
  const [currentCommunitySwiperIndex, setCurrentCommunitySwiperIndex] = useState<number>(0);

  const [selectedNewsIndex, setSelectedNewsIndex] = useState<number>(0);
  const [currentNewsSwiperIndex, setCurrentNewsSwiperIndex] = useState<number>(0);

  const newsInfo = { communityId: 1, boardId: 68, title: '상장정보통' };
  const dailyReportsInfo = { communityId: 1, boardId: 73, title: '상장일보' };
  const humorInfo = { communityId: 0, boardId: 53, title: '유머게시판' };

  const init = async () => {
    const news1: Array<PostDto> = (await communityStore.getPosts(
      newsInfo.communityId,
      newsInfo.boardId,
      5,
    )) as unknown as Array<PostDto>;

    const dailyReport: Array<PostDto> = (await communityStore.getPosts(
      dailyReportsInfo.communityId,
      dailyReportsInfo.boardId,
    )) as unknown as Array<PostDto>;

    const todaysHumor: Array<PostDto> = (await communityStore.getPosts(
      humorInfo.communityId,
      humorInfo.boardId,
    )) as unknown as Array<PostDto>;

    const newPosts: Array<PostDto> = (await communityStore.getPosts(
      0,
    )) as unknown as Array<PostDto>;

    setDailyReports(dailyReport);
    setNews(news1);
    setHumors(todaysHumor);
    setNewPosts(newPosts);

    userStore.getMyInfo();
    marketStore.getSuggestedItems();
    propertyStore.getProperties();
    communityPostStore.getPosts();
    communityPostStore.getHotIssuePosts();
  };

  useEffect(() => {
    if (typeof authStore.userToken !== 'undefined') {
      init();
    }
  }, [authStore.userToken]);

  useEffect(() => {
    if (typeof marketStore.items !== 'undefined') {
      setLoaded(true);
    }
  }, [marketStore.items]);

  return (
    <MainLayoutStyle>
      <RowStyle style={{ marginBottom: 20 }}>
        <Greetings name={userStore.userInfo?.name} />
        <Avatar
          src={`${userStore.userInfo?.profileImage?.thumbnailUrl}`}
          onClick={() => navigateTargetScreen('profile')}
        />
      </RowStyle>
      <Section2Style
        style={{ borderRadius: 10, overflow: 'hidden' }}
        onClick={() => {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ deepLink: `sangjang://community/post?post_id=1578&isReply=false` }),
          );
        }}
      >
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src="./images/mjkim1.png"
          alt=""
        />
      </Section2Style>
      <SectionStyle>
        <RowStyle style={{ justifyContent: 'space-between', padding: '10px 0' }}>
          <RowStyle style={{ justifyContent: 'flex-start' }}>
            <HeadlineStyle
              selected={selectedNewsIndex === 0}
              onClick={() => {
                setCurrentNewsSwiperIndex(0);
              }}
            >
              상장뉴스
            </HeadlineStyle>
            <div style={{ margin: '0 10px', position: 'relative', top: -8, opacity: 0.4 }}>|</div>
            <HeadlineStyle
              selected={selectedNewsIndex === 1}
              onClick={() => {
                setCurrentNewsSwiperIndex(1);
              }}
            >
              상장정보통
            </HeadlineStyle>
          </RowStyle>
          <MoreButtonStyle
            onClick={() => {
              if (currentNewsSwiperIndex === 0) {
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    deepLink: `sangjang://community/list?community_id=${newsInfo.communityId}&board_id=${newsInfo.boardId}&board_name=${newsInfo.title}`,
                  }),
                );
              } else {
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    deepLink: `sangjang://community/list?community_id=${dailyReportsInfo.communityId}&board_id=${dailyReportsInfo.boardId}&board_name=${dailyReportsInfo.title}`,
                  }),
                );
              }
            }}
          >
            {/* <img src="./images/arrow-right.png" alt="" /> */}
            더보기
          </MoreButtonStyle>
        </RowStyle>
        <NewsSwiper
          swiperIndex={currentNewsSwiperIndex}
          items={[{ items: news, options: { useThumbnail: false } }, { items: dailyReports }]}
          onSwipeChange={(e: any) => {
            setSelectedNewsIndex(e.activeIndex);
          }}
        />
      </SectionStyle>
      <SectionStyle>
        <RowStyle style={{ padding: '10px 0' }}>
          <HeadlineStyle selected>장터추천상품</HeadlineStyle>
          {/* <div>더보기</div> */}
        </RowStyle>
        <ItemList items={marketStore.items} type="market" />
      </SectionStyle>
      <SectionStyle>
        <RowStyle style={{ justifyContent: 'flex-start', padding: '10px 0' }}>
          <HeadlineStyle
            selected={selectedCommunityIndex === 0}
            onClick={() => {
              setCurrentCommunitySwiperIndex(0);
            }}
          >
            번영회 최신글
          </HeadlineStyle>
          <div style={{ margin: '0 10px', position: 'relative', top: -8 }}>|</div>
          <HeadlineStyle
            selected={selectedCommunityIndex === 1}
            onClick={() => {
              setCurrentCommunitySwiperIndex(1);
            }}
          >
            인기글
          </HeadlineStyle>
        </RowStyle>
        <NewsSwiper
          swiperIndex={currentCommunitySwiperIndex}
          items={[{ items: newPosts }, { items: communityPostStore.hotPosts }]}
          onSwipeChange={(e: any) => {
            setSelectedCommunityIndex(e.activeIndex);
          }}
        />
      </SectionStyle>
      <SectionStyle>
        <RowStyle style={{ padding: '10px 0' }}>
          <HeadlineStyle selected>부동산 최신매물</HeadlineStyle>
          {/* <div>더보기</div> */}
        </RowStyle>
        <ItemList items={propertyStore.properties} type="property" />
      </SectionStyle>

      <SectionStyle>
        <RowStyle style={{ padding: '10px 0' }}>
          <HeadlineStyle selected>오늘의 유머</HeadlineStyle>
          <MoreButtonStyle
            onClick={() => {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  deepLink: `sangjang://community/list?community_id=${humorInfo.communityId}&board_id=${humorInfo.boardId}&board_name=${humorInfo.title}`,
                }),
              );
            }}
          >
            {/* <img src="./images/arrow-right.png" alt="" /> */}
            더보기
          </MoreButtonStyle>
        </RowStyle>
        <ThumbnailList items={humors} />
      </SectionStyle>
      <Section2Style
        onClick={() => {
          // navigate('/viewer/0/1209');
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              deepLink: `sangjang://community/post?post_id=1210&isReply=false`,
            }),
          );
        }}
      >
        <img src="./images/sub-banner.png" alt="" />
      </Section2Style>

      <OverlayStyle loaded={loaded} />
      <LogoStyle loaded={loaded}>
        <div>
          <div style={{ width: 60, height: 60, borderRadius: 100, overflow: 'hidden' }}>
            <img style={{ width: 60, height: 60 }} src="./images/sjlogo.jpg" alt="" />
          </div>
          <CircularProgressStyle>
            <CircularProgress size={90} color="secondary" />
          </CircularProgressStyle>
        </div>
      </LogoStyle>
    </MainLayoutStyle>
  );
});

const MainLayoutStyle = styled.div``;
const Section2Style = styled.div`
  border-radius: 15px;
  width: 100%;
  & img {
    width: 100%;
  }
`;
const SectionStyle = styled.div`
  margin: 20px 0;
  background: #fff;
  padding: 15px;
  border-radius: 15px;

  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.2);
`;

const HeadlineStyle = styled.div<{ selected?: boolean }>`
  font-size: 18x;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  color: ${(props) => (props.selected ? '#000000' : '#666')};
  margin-bottom: 15px;
`;

const OverlayStyle = styled.div<{ loaded: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: ${(props) => (props.loaded ? 0 : 1)};
  z-index: 100;
  transition: opacity 0.5s;
  pointer-events: none;
  /* pointer-events: ${(props) => (props.loaded ? 'none' : 'none')}; */
`;

const LogoStyle = styled.div<{ loaded: boolean }>`
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: ${(props) => (props.loaded ? 0 : 1)};
  z-index: 101;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const CircularProgressStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
`;

const MoreButtonStyle = styled.div`
  position: relative;
  top: -10px;
  font-size: 14px;
  opacity: 0.6;
`;

export default CommunityPage;
