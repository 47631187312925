import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { RefreshToken } from 'libs/commons/RefreshToken';
import theme from 'resources/styles/common/theme';
import ScrollTop from 'libs/commons/ScrollTop';
import ErrorBoundary from 'ErrorBoundary';
import LayoutWrapper from 'LayoutWrapper';
import process from 'process';
import 'react-toastify/dist/ReactToastify.css';
import 'resources/css/Common.css';
import 'resources/css/Core.css';

/** RN 관련 interface 등록 */
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const App = observer(() => {
  const { authStore } = useStore();
  /** RN -> Web 메세지 받기 */
  const onMessageHandler = async (e: any) => {
    try {
      const event = JSON.parse(e.data);
      if (typeof event.backHandler !== 'undefined') {
        window.history.back();
      }
      let accessToken;
      let refreshToken;

      if (typeof event?.userToken === 'object') {
        accessToken = event?.userToken?.accessToken;
        refreshToken = event?.userToken?.refreshToken;
      } else {
        accessToken = JSON.parse(event?.userToken)?.accessToken;
        refreshToken = JSON.parse(event?.userToken)?.refreshToken;
      }

      if (typeof event?.deviceInfo === 'object') {
        await localStorage.setItem('deviceInfo', JSON.stringify(event?.deviceInfo));
      } else {
        await localStorage.setItem('deviceInfo', event?.deviceInfo);
      }

      if (typeof event !== 'undefined') {
        await localStorage.setItem('accessToken', accessToken);
        await localStorage.setItem('refreshToken', refreshToken);
        await RefreshToken(undefined);

        authStore.setUserToken({ accessToken, refreshToken });
      }
    } catch (e) {
      await RefreshToken(undefined);
      authStore.setUserToken({});
      localStorage.setItem('httpError', e);
      alert(
        `죄송합니다. 데이터 취득중 오류가 발생하였습니다. 화면을 아래로 당겨 새로고침 해 주세요. ${e}`,
      );
    }
  };

  // useEffect(() => {
  //   /** drawer가 열린것을 감지해서 RN으로 열렸다는것을 플래그를 보낸다 */
  //   if (uiStore.drawer.drawerShow) {
  //     window?.ReactNativeWebView?.postMessage(JSON.stringify({ isDrawer: true }));
  //   } else {
  //     window?.ReactNativeWebView?.postMessage(JSON.stringify({ isDrawer: false }));
  //   }
  // }, [uiStore.drawer.drawerShow]);

  /** RN (Android) BackHandler 감지 구현 */
  useEffect(() => {
    const isUIWebView = () => {
      return navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/);
    };
    const receiver = isUIWebView() ? window : document;

    receiver.addEventListener('message', onMessageHandler);
    return () => {
      receiver.removeEventListener('message', onMessageHandler);
    };
  }, []);

  useEffect(() => {
    // if (process.env.NODE_ENV === 'development') {
    //   const token = authStore.login('01041558477');
    //   authStore.setUserToken({});
    // }
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/*" element={<LayoutWrapper />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
});

export default App;
