import { setTokens } from 'libs/helper/localStorageHelper';
import { makeAutoObservable } from 'mobx';
import { User as UserService } from 'services/User';
import { TokenDto, UserDto } from 'services/data-contracts';
// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';
import { AxiosRequestHeaders } from 'axios';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  error?: any;
  statusText?: string;
}

export interface isValid {
  isValid: boolean;
}

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: UserService<unknown> = new UserService({
    baseURL: REST_API_URL,
  });

  users: UserDto;

  userToken: any;

  status: number;

  userNameIsValid: isValid;

  emailIsValid: isValid;

  authError: any;

  clearStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('me');
    localStorage.removeItem('refreshToken');
  }
  /** ============================================================================================== */

  *login(phoneNumber: string) {
    const { data, error, status } = yield this.api.login(
      {
        phoneNumber,
        verificationCode: '111111',
      },
      {
        test: true,
      },
      {
        headers: {
          'device-model': 'test',
          'device-os': 'ios',
          'app-version': '1.2',
          'device-token': '2222',
          'push-token': '3333',
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    if (data !== null) {
      setTokens(data);
      return data;
    } else {
      this.authError = error;
      return { result: false, error };
    }
  }

  logout = () => {
    /** 에디터에 intro 효과를 본 경험이 있는지 여부 */
    const editorIntro = localStorage.getItem('editorIntro');

    if (editorIntro !== null) {
      this.clearStorage();
      localStorage.setItem('editorIntro', 'true');
    } else {
      this.clearStorage();
    }
    window.location.href = '/login';
  };

  *refreshToken(token: TokenDto) {
    // const { data } = yield this.api.refreshToken(token);
    // return data;
  }

  setUserToken(token: any) {
    this.userToken = token;
  }

  // *duplicatePhoneNumber(dupEmail: any) {
  //   const { data } = yield this.api.duplicatePhoneNumber({ email: dupEmail });
  //   this.emailIsValid = data;
  //   if (data.isValid === true) {
  //     return { newEmail: true };
  //   } else {
  //     return { newEmail: false };
  //   }
  // }
}

export default AuthStore;
